import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/dashboard/Dashboard.vue";
import About from "../views/pages/About.vue";
import PrivacyPolicies from "../views/pages/PrivacyPolicies.vue";
import RequestAnalysis from "../views/pages/RequestAnalysis.vue";

import Faq from "../views/pages/Faq.vue";
import Login from "../views/Login.vue";
import Users from "../views/users/Users.vue";
import Companies from "../views/companies/Companies";
import Properties from "../views/properties/Properties";
import Settings from "../views/dashboard/Settings";
import Profile from "../views/dashboard/Profile";

import Vuex from "vuex";

Vue.use(Vuex);
Vue.use(VueRouter);

const company = "Conectar";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: `Login – ${company}`,
      slug: "login",
    },
  },
  {
    path: "/esqueci-minha-senha",
    name: "Login",
    component: Login,
    meta: {
      title: `${company} - Recuperar senha`,
      slug: "esqueci-minha-senha",
    },
  },
  {
    path: "/cadastro",
    name: "RegisterCustomers",
    component: Login,
    meta: {
      title: `${company} - Cadastrar`,
      slug: "cadastrar",
    },
  },
  {
    path: "/key",
    name: "Login",
    component: Login,
    meta: {
      title: `${company} - Resetar senha`,
      slug: "key",
    },
    children: [
      {
        path: ":hash",
        component: Login,
        meta: {
          title: `${company} - Resetar senha`,
          slug: "key",
        },
      },
    ],
  },
  {
    path: "/password",
    name: "Login",
    component: Login,
    meta: {
      title: `${company} - Definir senha`,
      slug: "password",
    },
    children: [
      {
        path: ":hash",
        component: Login,
        meta: {
          title: `${company} - Definir senha`,
          slug: "password",
        },
      },
    ],
  },
  {
    path: "/",
    name: "Painel",
    component: Dashboard,
    meta: {
      title: `Painel – ${company}`,
      requiresAuth: true,
    },
  },
  {
    path: "/operacao-credito/solicitar-analise",
    name: "Operation",
    component: RequestAnalysis,
    meta: {
      title: `${company} - Solicitar Análise`,
      requiresAuth: true,
    }
  },
  {
    path: "/usuarios/:action",
    name: "Users",
    component: Users,
    meta: {
      title: `${company} - Usuários`,
      requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        component: Users,
        meta: {
          title: `${company} - Ver Usuário`,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/afiliados/:action",
    name: "Users",
    component: Users,
    meta: {
      title: `${company} - Afiliados`,
      requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        component: Users,
        meta: {
          title: `${company} - Ver Afiliado`,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/empresas/:action",
    name: "Companies",
    component: Companies,
    meta: {
      title: `${company} - Empresas`,
      requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        component: Companies,
        meta: {
          title: `${company} - Ver Empresa`,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/ativos/:action",
    name: "Properties",
    component: Properties,
    meta: {
      title: `${company} - Ativos`,
      requiresAuth: true,
    },
    children: [
      {
        path: ":id",
        component: Properties,
        meta: {
          title: `${company} - Ver Ativo`,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/quem-somos",
    name: "About",
    component: About,
    meta: {
      title: `Quem Somos – ${company}`,
      requiresAuth: true,
    },
  },
  {
    path: "/politica-privacidade",
    name: "Política de Privacidade",
    component: PrivacyPolicies,
    meta: {
      title: `Política de Privacidade – ${company}`,
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
    meta: {
      title: `FAQ – ${company}`,
      requiresAuth: true,
    },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: Profile,
    meta: {
      title: `Meu Perfil – ${company}`,
      requiresAuth: true,
    },
  },
  {
    path: "/configuracoes",
    name: "Configurações",
    component: Settings,
    meta: {
      title: `Configurações – ${company}`,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
