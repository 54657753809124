<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <a-layout
        v-if="$store.state.isLoggedIn"
        id="components-layout-demo-responsive"
        class="dashboard"
      >
        <a-layout-sider
          v-if="$root.windowWidth > 961"
          breakpoint="lg"
          collapsed-width="0"
          width="220"
        >
          <SideMenu />
        </a-layout-sider>

        <a-layout>
          <a-layout-header class="mobile-header" v-if="$root.windowWidth < 960">
            <HeaderMobile :totalUnread="totalUnread" />
          </a-layout-header>

          <a-layout-header v-if="$root.windowWidth > 961">
            <HeaderDesktop :totalUnread="totalUnread" />
          </a-layout-header>

          <a-layout-content :style="{ margin: '24px 16px 0' }">
            <router-view />
          </a-layout-content>

          <a-modal
            class="conectar-info"
            width="400px"
            :visible="expiredTokenModal"
            :closable="false"
            :footer="false"
            @ok="expiredTokenModal = true"
            @cancel="expiredTokenModal = true"
          >
            <h2 class="a-center">
              <a-icon class="mr-10" type="close-circle" /> OOPS...
            </h2>
            <p class="a-center">
              Seu token de acesso expirou. Acesse o sistema novamente.
            </p>
            <center class="mt-20">
              <a-button @click="clearUserData()" size="large" type="primary">
                Renovar Token
              </a-button>
            </center>
          </a-modal>

          <a-modal
            class="conectar-info"
            width="400px"
            :visible="openProfilePictureModal"
            :closable="false"
            :footer="false"
            @ok="openProfilePictureModal = true"
            @cancel="openProfilePictureModal = true"
          >
            <h2>OOPS...</h2>
            <center>
              <img
                class="no-avatar"
                src="../src/assets/images/no-avatar.png"
                alt="no-avatar"
              />
            </center>
            <p>
              Parece que você não cadastrou a sua foto de perfil.
            </p>

            <p>
              Cadastre sua foto agora para finalizar seu cadastro.
            </p>

            <div class="a-right">
              <a-button
                @click="openProfilePictureModal = false"
                type="link"
                size="large"
              >
                acessar meu perfil
              </a-button>
            </div>
          </a-modal>

          <a-tooltip placement="left">
            <template slot="title">
              <span
                >Precisa de ajuda? Clique aqui e nos mande sua mensagem!</span
              >
            </template>
            <a-button
              @click="openWhatsapp('35 98825 6443')"
              id="whatsapp"
              shape="round"
              ><i class="fab fa-whatsapp"></i
            ></a-button>
          </a-tooltip>
        </a-layout>
      </a-layout>

      <router-view v-else />
    </a-config-provider>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { isJwtExpired } from "jwt-check-expiration";

import ptBr from "ant-design-vue/lib/locale-provider/pt_BR";
import logo from "./assets/images/conectar-ico.png";
import HeaderMobile from "./components/general/HeaderMobile.vue";
import HeaderDesktop from "./components/general/HeaderDesktop.vue";
import SideMenu from "./components/general/SideMenu.vue";

export default {
  name: "App",
  components: {
    HeaderMobile,
    HeaderDesktop,
    SideMenu,
  },
  data() {
    return {
      locale: ptBr,
      logo,
      isLoggedIn: this.$store.isLoggedIn,
      notifications: [],
      totalUnread: 0,
      expiredTokenModal: false,
      openProfilePictureModal: false,
    };
  },
  computed: {
    ...mapState(["capabilities"]),
  },
  updated() {
    this.verifyAvatar();
  },
  beforeMount() {
    this.verifyAvatar();
    this.setCapabilities();
  },
  mounted() {
    this.getNotifications();

    this.tokenExpired = isJwtExpired(this.$store.state.token);

    if (this.tokenExpired) {
      setTimeout(() => {
        this.logOut();
      }, 4000);
    }
  },
  methods: {
    ...mapActions(["setCapabilities"]),
    verifyAvatar() {
      if (this.$root.isLoggedIn) {
        if (!this.$root.haveAvatar()) {
          if (this.$route.name != "Perfil") {
            this.$router.push("/perfil");
            this.openProfilePictureModal = true;
          }
        }
      }
    },
    openWhatsapp(number) {
      let theNumber = number
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "");
      window.open(
        `https://api.whatsapp.com/send?phone=55${theNumber}&text=`,
        "_blank"
      );
    },
    clearUserData() {
      localStorage.removeItem("userData");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      window.open("/login", "_self");
    },
    logOut() {
      let user = JSON.parse(localStorage.userData);
      if (this.tokenExpired) {
        this.expiredTokenModal = true;
      } else {
        this.$http
          .post("/system-logs/create.php", {
            id: user.id,
            content: `<font class="user">${user.first_name} ${user.last_name}</font> deslogou do sistema.`,
            action: "loggedout",
          })
          .then(({ data }) => {
            data;
            localStorage.removeItem("userData");
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.open("/login", "_self");
          })
          .catch(({ response }) => {
            response;
          });
      }
    },
    getNotifications() {
      this.$http
        .post(
          "/notification/list.php?page=1&per_page=100&user_id=" +
            this.$store.state.userData.id
        )
        .then(({ data }) => {
          this.totalUnread = data.meta.total_unread;
        })
        .catch(({ response }) => {
          response;
        });
    },
  },
};
</script>

<style lang="sass">
@import '/assets/sass/style.sass'
</style>
