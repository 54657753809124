<template>
    <div>
        <a-steps
            v-if="
                $root.reponsive([
                    {
                        min: 0,
                        max: 620,
                        span: false,
                    },
                    {
                        min: 621,
                        max: 9999,
                        span: true,
                    },
                ])
            "
            class="mb-20"
            type="navigation"
            size="small"
            v-model="createProperty.activeStep"
            @change="changeSteps"
        >
            <a-step status="process" title="Localização" />
            <a-step
                :status="createProperty.activeStep >= 1 ? 'process' : 'wait'"
                :disabled="createProperty.activeStep <= 1"
                title="Dados do Ativo"
            />
            <a-step
                :status="createProperty.activeStep >= 2 ? 'process' : 'wait'"
                :disabled="createProperty.activeStep <= 2"
                title="Documentos"
            />
        </a-steps>

        <a-row v-show="createProperty.activeStep == '0'" :gutter="16">
            <a-form :form="form" @submit="handleSubmit" style="padding:0 10px">
                <a-row style="padding:0 10px">
                    <a-col :span="24">
                        <a-form-item class="mb-0">
                            <a-radio-group
                                v-decorator="[
                                    'is_owner',
                                    {
                                        initialValue: 'yes',
                                        rules: [
                                            {
                                                required: validateFourthStep(),
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                                default-value="yes"
                            >
                                <a-radio value="yes">
                                    Sou proprietário do Ativo
                                </a-radio>
                                <a-radio value="no">
                                    Sou Intermediador
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>

                    <div class="clearfix" />

                    <a-row
                        v-if="form.getFieldValue('is_owner') == 'no'"
                        :gutter="16"
                    >
                        <a-col
                            :span="
                                $root.reponsive([
                                    {
                                        min: 0,
                                        max: 620,
                                        span: 24,
                                    },
                                    {
                                        min: 621,
                                        max: 9999,
                                        span: 12,
                                    },
                                ])
                            "
                        >
                            <a-form-item class="mb-10 underline-wrapper">
                                <label
                                    :class="
                                        form.getFieldValue('owner_first_name')
                                            ? 'filled'
                                            : ''
                                    "
                                    >Nome do proprietário do ativo</label
                                >
                                <a-input
                                    class="underline-input"
                                    placeholder="Nome do proprietário do ativo"
                                    v-decorator="[
                                        'owner_first_name',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Obrigatório',
                                                },
                                            ],
                                        },
                                    ]"
                                >
                                </a-input>
                            </a-form-item>
                        </a-col>

                        <a-col
                            :span="
                                $root.reponsive([
                                    {
                                        min: 0,
                                        max: 620,
                                        span: 24,
                                    },
                                    {
                                        min: 621,
                                        max: 9999,
                                        span: 12,
                                    },
                                ])
                            "
                        >
                            <a-form-item class="mb-10 underline-wrapper">
                                <label
                                    :class="
                                        form.getFieldValue('owner_last_name')
                                            ? 'filled'
                                            : ''
                                    "
                                    >Sobrenome do proprietário do ativo</label
                                >
                                <a-input
                                    class="underline-input"
                                    placeholder="Sobrenome do proprietário do ativo"
                                    v-decorator="[
                                        'owner_last_name',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Obrigatório',
                                                },
                                            ],
                                        },
                                    ]"
                                >
                                </a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-row>

                <div class="clearfix" />

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 16,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('name') ? 'filled' : ''
                            "
                            >Nome do ativo Imobiliário</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Nome do ativo Imobiliário"
                            v-decorator="[
                                'name',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('cep') ? 'filled' : ''
                            "
                            >CEP</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="CEP (opcional)"
                            v-mask="['#####-###']"
                            v-decorator="[
                                'cep',
                                {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                            @keyup="getLocation()"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 19,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('location_street')
                                    ? 'filled'
                                    : ''
                            "
                            >Endereço</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Endereço"
                            v-decorator="[
                                'location_street',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 5,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('location_number')
                                    ? 'filled'
                                    : ''
                            "
                            >Número</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Número"
                            v-decorator="[
                                'location_number',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 16,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('location_complement')
                                    ? 'filled'
                                    : ''
                            "
                            >Complemento</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Complemento"
                            v-decorator="['location_complement']"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('location_neighborhood')
                                    ? 'filled'
                                    : ''
                            "
                            >Bairro</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Bairro"
                            v-decorator="[
                                'location_neighborhood',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 10,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('location_city')
                                    ? 'filled'
                                    : ''
                            "
                            >Cidade</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Cidade"
                            v-decorator="[
                                'location_city',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 6,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('location_state')
                                    ? 'filled'
                                    : ''
                            "
                            >Estado</label
                        >
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="Estado"
                            v-decorator="[
                                'location_state',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(item, index) of states"
                                :key="index"
                                :value="item.initials"
                            >
                                {{ item.initials.toUpperCase() }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                this.form.getFieldValue('population')
                                    ? 'filled'
                                    : ''
                            "
                            >Habitantes</label
                        >
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="Habitantes"
                            v-decorator="[
                                'population',
                                {
                                    rules: [
                                        {
                                            required: validateFirstStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(item, index) of population"
                                :key="index"
                                :value="item.label"
                            >
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <div class="clearfix" />

                <a-row type="flex" justify="end" style="padding: 0 10px">
                    <a-col>
                        <a-button
                            class="mt-20"
                            type="primary"
                            size="large"
                            @click="goToSecondStep"
                            style="width: 100%"
                        >
                            Continuar <a-icon type="arrow-right" />
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-row>

        <a-row v-show="createProperty.activeStep == '1'" :gutter="16">
            <a-form :form="form2" @submit="handleSubmit" style="padding:0 10px">
                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                form2.getFieldValue('registration')
                                    ? 'filled'
                                    : ''
                            "
                            >Nº da Matrícula</label
                        >
                        <a-input
                            type="number"
                            class="underline-input"
                            placeholder="Nº da Matrícula"
                            v-decorator="[
                                'registration',
                                {
                                    rules: [
                                        {
                                            required: validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 6,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="form2.getFieldValue('zone') ? 'filled' : ''"
                            >Zoneamento</label
                        >
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="Zoneamento"
                            v-decorator="[
                                'zone',
                                {
                                    rules: [
                                        {
                                            required: validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option value="Residencial">
                                Residencial
                            </a-select-option>
                            <a-select-option value="Industrial">
                                Industrial
                            </a-select-option>
                            <a-select-option value="Misto">
                                Misto
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 10,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                form2.getFieldValue('metreage') ? 'filled' : ''
                            "
                            >Metragem (m²)
                        </label>
                        <a-input
                            class="underline-input"
                            placeholder="Metragem (m²)"
                            v-currency="{
                                currency: {
                                    prefix: '',
                                    suffix: '',
                                },
                                distractionFree: false,
                                valueAsInteger: false,
                                precision: 3,
                                autoDecimalMode: true,
                                allowNegative: false,
                            }"
                            v-decorator="[
                                'metreage',
                                {
                                    rules: [
                                        {
                                            required: validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                    <div
                        style="font-size: 9px; font-weight: 600; position: absolute; bottom: -2px"
                    >
                        {{ checkDecimals(form2.getFieldValue("metreage")) }}
                    </div>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="form2.getFieldValue('onus') ? 'filled' : ''"
                            >Possui ônus?</label
                        >
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="Possui ônus?"
                            v-decorator="[
                                'onus',
                                {
                                    rules: [
                                        {
                                            required: validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option value="Sim">
                                Sim
                            </a-select-option>
                            <a-select-option value="Não">
                                Não
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                form2.getFieldValue('business_type')
                                    ? 'filled'
                                    : ''
                            "
                            >Vocação</label
                        >
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="Vocação"
                            v-decorator="[
                                'business_type',
                                {
                                    rules: [
                                        {
                                            required: validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(v, index) in vocations"
                                :key="index"
                                :value="v.value"
                            >
                                {{ v.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                form2.getFieldValue('situation') ? 'filled' : ''
                            "
                            >Situação
                        </label>
                        <a-select
                            class="underline-input"
                            show-search
                            placeholder="Situação"
                            v-decorator="[
                                'situation',
                                {
                                    rules: [
                                        {
                                            required: validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option value="Ocupado">
                                Ocupado
                            </a-select-option>
                            <a-select-option value="Desocupado">
                                Desocupado
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 8,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10 underline-wrapper">
                        <label
                            :class="
                                form2.getFieldValue('value') ? 'filled' : ''
                            "
                            >Valor (R$)</label
                        >
                        <a-input
                            class="underline-input"
                            placeholder="Valor (R$)"
                            v-currency="{
                                currency: {
                                    prefix: '',
                                    suffix: '',
                                },
                                distractionFree: false,
                                valueAsInteger: false,
                                precision: 2,
                                autoDecimalMode: true,
                                allowNegative: false,
                            }"
                            :disabled="checkNegotiation"
                            v-decorator="[
                                'value',
                                {
                                    rules: [
                                        {
                                            required: checkNegotiation
                                                ? false
                                                : validateSecondStep(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col
                    :span="
                        $root.reponsive([
                            {
                                min: 0,
                                max: 620,
                                span: 24,
                            },
                            {
                                min: 621,
                                max: 9999,
                                span: 12,
                            },
                        ])
                    "
                >
                    <a-form-item class="mb-10">
                        <a-checkbox
                            :checked="checkNegotiation"
                            @change="changeCheckNegotiation"
                            v-decorator="['negotiate_value']"
                        >
                            Valor a negociar
                        </a-checkbox>
                    </a-form-item>
                </a-col>

                <a-row :gutter="20" style="padding: 20px 10px 0; clear: both">
                    <a-col class=" " :span="24">
                        <a-form-item
                            class="underline-wrapper textarea-dashed textarea"
                        >
                            <label
                                :class="
                                    form2.getFieldValue('property_description')
                                        ? 'filled'
                                        : ''
                                "
                                >Descrição do ativo</label
                            >

                            <a-textarea
                                placeholder="Descreva por completo o ativo imobiliario"
                                :auto-size="{ minRows: 5, maxRows: 5 }"
                                v-decorator="[
                                    'property_description',
                                    {
                                        rules: [
                                            {
                                                required: validateSecondStep(),
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            />
                        </a-form-item>
                    </a-col>

                    <a-col :span="24">
                        <a-form-item
                            class="mb-10 underline-wrapper textarea-dashed textarea"
                        >
                            <label
                                :class="
                                    form2.getFieldValue('commercial_proposal')
                                        ? 'filled'
                                        : ''
                                "
                                >Proposta comercial</label
                            >

                            <a-textarea
                                placeholder="Descreva sua proposta comercial"
                                :auto-size="{ minRows: 5, maxRows: 5 }"
                                v-decorator="[
                                    'commercial_proposal',
                                    {
                                        rules: [
                                            {
                                                required: validateSecondStep(),
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            />
                        </a-form-item>
                    </a-col>

                    <div class="clearfix" />

                    <a-row
                        type="flex"
                        justify="space-between"
                        style="padding: 0 10px"
                    >
                        <a-col>
                            <a-button
                                class="mt-20"
                                size="large"
                                @click="backToFirstStep"
                                style="width: 100%"
                            >
                                <a-icon type="arrow-left" /> Voltar
                            </a-button>
                        </a-col>

                        <a-col>
                            <a-button
                                class="mt-20"
                                type="primary"
                                size="large"
                                @click="goToThirdStep"
                            >
                                Continuar <a-icon type="arrow-right" />
                            </a-button>
                        </a-col>
                    </a-row>
                </a-row>
            </a-form>
        </a-row>

        <a-row v-show="createProperty.activeStep == '2'" :gutter="16">
            <a-form :form="form3" @submit="handleSubmit" style="padding:0 10px">
                <a-row style="padding: 0 10px">
                    <a-col class="media-upload" :span="24">
                        <div class="label">
                            Matrícula do terreno
                            <font style="color: red">*</font>
                        </div>

                        <!-- validateThirdStep() -->
                        <a-form-item>
                            <a-upload-dragger
                                name="file"
                                :customRequest="uploadRegistrationFile"
                                :file-list="registration_docs.list"
                                :remove="removeRegistrationFile"
                                :multiple="false"
                                accept=".pdf,.png,.jpg,.jpeg"
                                v-decorator="[
                                    'area_registration_docs',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <p class="ant-upload-drag-icon">
                                    <a-icon type="picture" />
                                </p>
                                <p class="ant-upload-text">
                                    <font class="font">Clique aqui</font> ou
                                    arraste o arquivo
                                </p>
                            </a-upload-dragger>

                            <a-icon
                                v-if="registration_docs.loading"
                                type="loading"
                            />
                        </a-form-item>
                    </a-col>

                    <a-divider />

                    <a-col class="mb-30 media-upload" :span="24">
                        <div class="label">
                            Marcação do terreno (KMZ)
                            <font style="color: red">*</font>
                        </div>

                        <a-form-item>
                            <a-upload-dragger
                                name="file"
                                :customRequest="uploadKMZFile"
                                :file-list="kmz.list"
                                :remove="removeKMZFile"
                                :multiple="false"
                                accept=".kmz"
                                v-decorator="[
                                    'kmz_file',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <p class="ant-upload-drag-icon">
                                    <a-icon type="picture" />
                                </p>
                                <p class="ant-upload-text">
                                    <font class="font">Clique aqui</font> ou
                                    arraste o arquivo
                                </p>
                            </a-upload-dragger>

                            <a-icon v-if="kmz.loading" type="loading" />
                        </a-form-item>
                        <!--
                        {{ kmz }} -->
                    </a-col>

                    <a-divider />

                    <a-col class="media-upload" :span="24">
                        <div class="label">
                            <!-- Fotos do terreno  -->
                            Projetos, espelho do IPTU e outros documentos/fotos
                        </div>

                        <a-form-item>
                            <a-upload-dragger
                                name="file"
                                accept=".pdf,.png,.jpg,.jpeg"
                                :customRequest="uploadPicturesFile"
                                :file-list="pictures.list"
                                :remove="removePicturesFile"
                                :multiple="false"
                                v-decorator="[
                                    'property_pictures',
                                    {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <p class="ant-upload-drag-icon">
                                    <a-icon type="picture" />
                                </p>
                                <p class="ant-upload-text">
                                    <font class="font">Clique aqui</font> ou
                                    arraste o arquivo
                                </p>
                            </a-upload-dragger>
                            <a-icon v-if="pictures.loading" type="loading" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="24">
                        <a-form-item class="mb-10 agree-conectar">
                            <a-checkbox
                                v-decorator="[
                                    'agreed_terms',
                                    {
                                        rules: [
                                            {
                                                required: validateThirdStep(),
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                Concordo com os
                            </a-checkbox>
                            <a
                                @click="openTermsOfService = true"
                                style="font-size: 13px; margin-left: -2px"
                                >Termos da Conectar Investimentos</a
                            >.
                        </a-form-item>

                        <a-modal
                            v-model="openTermsOfService"
                            title="Termo de uso do APP CONECTAR"
                            footer
                            width="700px"
                            @click="openTermsOfService = false"
                        >
                            <div v-html="termsOfUse"></div>
                        </a-modal>
                    </a-col>
                </a-row>

                <div class="clearfix" />

                <a-row
                    type="flex"
                    justify="space-between"
                    style="padding: 0 10px"
                >
                    <a-col>
                        <a-button
                            class="mt-20"
                            size="large"
                            @click="backToSecondStep"
                            style="width: 100%"
                        >
                            <a-icon type="arrow-left" /> Voltar
                        </a-button>
                    </a-col>

                    <a-col>
                        <a-button
                            type="primary"
                            size="large"
                            :loading="loadingCreateProperty"
                            @click="submitProperty"
                        >
                            Cadastrar ativo
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-row>
    </div>
</template>

<script>
import axios from "axios";
import states from "../../lists/states.json";
import population from "../../lists/population.json";
import uploadFiles from "../../mixins/property/uploadFiles.js";
import vocations from "../../lists/vocations.json";

export default {
    mixins: [uploadFiles],
    data() {
        return {
            form: this.$form.createForm(this),
            form2: this.$form.createForm(this),
            form3: this.$form.createForm(this),
            termsOfUse: null,
            vocations,
            loadingCreateProperty: false,
            loadingFiles: false,
            checkNegotiation: false,
            openTermsOfService: false,
            userPassword: true,
            loading: false,
            states,
            population,
            createProperty: {
                activeStep: 0,
            },
        };
    },
    mounted() {
        // this.form.setFieldsValue({
        //     owner_id: 38,
        //     name: "Led Area",
        //     cep: "35400000",
        //     location_state: "MG",
        //     location_city: "Ouro Branco",
        //     location_street: "Rua São João Del Rei",
        //     location_number: "1000",
        //     location_neighborhood: "Saraiva",
        //     location_complement: "Casa",
        //     population: "de 50.000 a 100.000",
        // });
        // this.form2.setFieldsValue({
        //     registration: "55451455",
        //     zone: "Residencial",
        //     onus: "Sim",
        //     metreage: "100,005",
        //     area: "até  5.000 m²",
        //     business_type: "Rural",
        //     property_description: "Lorem ipsum dolor asit amet",
        //     commercial_proposal: "Lorem ipsum dolor asit amet 2",
        //     value: "2.000.000,05",
        //     situation: "Ocupado",
        //     status: "Ativo",
        //     rate: 0,
        // });

        this.$http
            .post("/external-apis/get-wp-pages.php", {
                id: "8370",
            })
            .then(({ data }) => {
                this.termsOfUse = data.content;
            })
            .catch(({ response }) => {
                response;
            });
    },
    methods: {
        checkDecimals(number) {
            let txt = ``;
            if (number) {
                txt = `*Atenção aos 3 zeros após vírgula. (${Number(
                    parseFloat(
                        number
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "")
                            .replace(",", ".")
                    ).toFixed(0)
                ).toLocaleString("pt-BR")} m²)`;
            }

            return txt;
        },
        normFile(e) {
            console.log("Upload event:", e);
            if (Array.isArray(e)) {
                return e;
            }
            return e && e.fileList;
        },
        changeCheckNegotiation(e) {
            this.checkNegotiation = e.target.checked;

            this.form2.setFieldsValue({
                value: "",
            });
        },
        goToSecondStep(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    this.createProperty.activeStep = 1;
                } else {
                    this.createProperty.activeStep = 0;
                }
            });
        },
        goToThirdStep(e) {
            console.log(this.createProperty);
            e.preventDefault();
            this.form2.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    this.createProperty.activeStep = 2;
                } else {
                    this.createProperty.activeStep = 1;
                }
            });
        },
        goToFourthStep(e) {
            e.preventDefault();
            this.form3.validateFields((err, values) => {
                console.log("FORM 3", err, values);
                if (!err) {
                    this.createProperty.activeStep = 3;
                } else {
                    this.createProperty.activeStep = 2;
                }
            });
        },
        backToFirstStep() {
            this.createProperty.activeStep = 0;
        },
        backToSecondStep() {
            this.createProperty.activeStep = 1;
        },
        backToThirdStep() {
            this.createProperty.activeStep = 2;
        },
        validateFirstStep() {
            let flag = false;

            if (this.createProperty.activeStep == 0) {
                flag = true;
            }

            return flag;
        },
        validateSecondStep() {
            let flag = false;

            if (this.createProperty.activeStep == 1) {
                flag = true;
            }

            return flag;
        },
        validateThirdStep() {
            let flag = false;

            if (this.createProperty.activeStep == 2) {
                flag = true;
            }

            return flag;
        },
        validateFourthStep() {
            let flag = false;

            if (this.createProperty.activeStep == 3) {
                flag = true;
            }

            return flag;
        },
        changeSteps(step) {
            step;
        },
        submitProperty(e) {
            e.preventDefault();

            let propertyData = {};
            let flag = false;

            this.form.validateFields((err, values) => {
                console.log(err, values);

                if (!err) {
                    propertyData = values;
                    flag = true;
                } else {
                    flag = false;
                }
            });

            this.form2.validateFields((err, values) => {
                //  values.value = `${values.value}`
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(",", ".");
                // values.metreage = `${values.metreage}`
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(".", "")
                //     .replace(",", ".");

                console.log(err, values);

                if (!err) {
                    propertyData = { ...propertyData, ...values };
                    flag = true;
                } else {
                    flag = false;
                }
            });

            this.form3.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    propertyData = { ...propertyData, ...values };
                    flag = true;
                } else {
                    flag = false;
                }
            });

            if (flag) {
                propertyData.device = navigator.userAgent;
                propertyData.owner_id = this.$store.state.userData.id;
                propertyData.status = "active";
                propertyData.negotiate_value = propertyData.negotiate_value
                    ? "1"
                    : "0";

                propertyData.value = `${propertyData.value}`
                    .replace(".", "")
                    .replace(".", "")
                    .replace(".", "")
                    .replace(".", "")
                    .replace(".", "")
                    .replace(",", ".");

                propertyData.metreage = `${propertyData.metreage}`
                    .replace(".", "")
                    .replace(".", "")
                    .replace(".", "")
                    .replace(".", "")
                    .replace(",", ".");

                propertyData.modified_by = {
                    name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                    email: `${this.$store.state.userData.email}`,
                    id: this.$store.state.userData.id,
                };

                //propertyData.test = true;

                this.loadingCreateProperty = true;

                this.$http
                    .post("/property/create.php", propertyData)
                    .then(({ data }) => {
                        this.loadingCreateProperty = false;
                        this.$message.success(data.message);

                        setTimeout(() => {
                            this.$emit("listPropertiesAfterCreate", true);
                            this.$emit("closeDrawerAfterCreate", true);

                            this.form.setFieldsValue({
                                owner_id: "",
                                name: "",
                                cep: "",
                                location_state: "",
                                location_city: "",
                                location_street: "",
                                location_number: "",
                                location_neighborhood: "",
                                location_complement: "",
                                registration: "",
                                zone: "",
                                onus: "",
                                population: null,
                                metreage: "",
                                area: "",
                                business_type: "",
                                property_description: "",
                                commercial_proposal: "",
                                value: "",
                                situation: "",
                                status: "",
                                rate: 0,
                            });
                        }, 500);
                    })
                    .catch(({ response }) => {
                        this.$message.error(response.data.message);
                        this.loadingCreateProperty = false;
                    });
            } else {
                console.log("erro");
            }
        },

        getLocation() {
            setTimeout(() => {
                if (this.form.getFieldValue("cep")) {
                    if (
                        this.form.getFieldValue("cep").replace("-", "")
                            .length == 8
                    ) {
                        this.cepLoading = true;
                        axios
                            .get(
                                "https://viacep.com.br/ws/" +
                                    this.form
                                        .getFieldValue("cep")
                                        .replace("-", "") +
                                    "/json/"
                            )
                            .then(({ data }) => {
                                data;
                                this.cepLoading = false;
                                this.form.setFieldsValue({
                                    location_street: data.logradouro,
                                    location_state: data.uf,
                                    location_city: data.localidade,
                                    location_neighborhood: data.bairro,
                                    location_number: data.complemento,
                                });
                            })
                            .catch(({ response }) => {
                                response;
                                this.$message.error(
                                    "Houve um problema ao recuperar a localização."
                                );
                                this.cepLoading = false;
                            });
                    }
                }
            }, 110);
        },
    },
};
</script>
