<template>
  <div>
    <aPageHeader class="bg">
      <aRow type="flex" justify="space-between">
        <aCol>
          <h1>Solicitar Análise</h1>
        </aCol>
      </aRow>
    </aPageHeader>

    <div class="iframe-wrapper">
      <aIcon class="loading-iframe" type="loading" />

      <iframe
        src="https://conectarinvestimentos.com/solicitar-analise-app"
        width="100%"
        height="1400px"
        loading="eager"
        style="border:none;"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestAnalysis",
};
</script>

<style lang="scss" scoped>
.iframe-wrapper {
  position: relative;
  .loading-iframe {
    position: absolute;
    font-size: 120px;
    left: calc(50% - 60px);
    color: #ddd;
    top: 60px;
    z-index: 0;
  }

  iframe {
    position: relative;
    z-index: 1;
    background: transparent;
  }
}
</style>
